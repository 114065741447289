import React, { useCallback, useEffect, useState } from 'react';
import { FetchService } from '../../services/Api';
import Breadcrum from '../common/breadcrumb/Breadcrumb';
import VenueSelector from '../common/venueSelector/VenueSelector';
import DateSelect from '../common/dateSelect/DateSelect';
import style from './Header.module.css';
import VenueBaseDateSelect from '../common/venueBaseDateSelect/VenueBaseDateSelect';
import Select from '../common/select/Select';
import { MONTHS } from '../pizzaTv/Utils.js';
import { getLastNDay } from '../../components/report/Aux.js'
import { useSearchParams } from 'react-router-dom';

const TS_START_OF_WEEK_CHANGE = '2024-03-05';

const Header = (props) => {
  const {
    breadcrumb,
    pageTitle,
    venues,
    customPeriods,
    selectedVenue,
    selectedDate,
    selectedDateRange,
    whenSelectingVenue,
    whenSelectingDate,
    whenSelectingDateRange,
    whenSelectingWeek,
    whenSelectingCompany,
    selectedCompany,
    selectedWeek,
    whenSelectingWeekDay,
    weekdayOptions,
    whenSelectingShift,
    shiftOptions,
    whenSelectingRecipe,
    recipeOptions,
    whenSelectingStandard,
    standarOptions,
    whenSelectingShowOnReport,
    showOnReportOptions,
    whenSelectingMonth,
    selectedMonth,
    whenPrettyNames,
    autoSelectFirstVenue
  } = props;

  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);
  const [companyMembership, setCompanyMembership] = useState(undefined);
  const [companiesOptions, setCompaniesOptions] = useState(undefined);
  const [customPeriodsOptions, setCustomPeriodsOptions] = useState(undefined);
  const [tsWeekStartChange, setTsWeekStartChange] = useState(undefined);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (tsWeekStartChange !== undefined) return;
    let tsStr = TS_START_OF_WEEK_CHANGE;
    let tsWeekChange = searchParams.get('ts_sunday_as_start_of_week');
    if (tsWeekChange) {
      let dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateRegex.test(tsWeekChange)) {
        tsStr = tsWeekChange;
      }
      else {
        window.alert("Invalid date format for 'ts_sunday_as_start_of_week' URL parameter (YYYY-MM-DD). Using default value '" + tsStr + "' instead.");
      }
    }
    let weekStartChange = new Date(tsStr);
    setTsWeekStartChange(weekStartChange);
  }, [searchParams, tsWeekStartChange])

  useEffect(() => {
    if (companyMembership !== undefined || whenSelectingCompany === undefined) return;
    let requestConf = {
      endpoint: `dashboard/company-membership/`,
      method: 'GET'
    }

    FetchService(requestConf).then(
      (response) => {
        setCompanyMembership(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [whenSelectingCompany, companyMembership])

  useEffect(() => {
    if (companyMembership === undefined) return;
    let companies = companyMembership.map(m => { return { displayName: m.space.pretty_name || m.space.name, pk: m.space.pk } });
    companies = companies.sort((a, b) => (a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName) ? -1 : 0);
    companies = [...new Set(companies.map(JSON.stringify))].map(JSON.parse);
    if (companies.length > 1) {
      setCompaniesOptions(companies.map(company => { return { value: company.pk, name: company.displayName, selected: company.pk === selectedCompany } }));
    }
    else if (companies.length === 1 && whenSelectingCompany) {
      whenSelectingCompany('', [{ value: companies[0].pk, name: companies[0].displayName }])
    }
  }, [companyMembership, selectedCompany, whenSelectingCompany])

  const getWeekOfYear = (date, tsWeekStartChange) => {
    const year = date.getUTCFullYear();
    const firstDayOfYear = new Date(year, 0, 1);
    firstDayOfYear.setUTCHours(0, 0, 0, 0);
    const dayOfWeek = firstDayOfYear.getUTCDay();
    const daysUntilFirstSunday = (7 - (dayOfWeek || 7)) % 7;
    const firstSunday = new Date(year, 0, daysUntilFirstSunday + 1);
    firstSunday.setUTCHours(0, 0, 0, 0);
    const diffDays = Math.floor((date - firstSunday) / (1000 * 60 * 60 * 24));
    if (date >= tsWeekStartChange) {
      return Math.floor(diffDays / 7) + (date.getUTCFullYear() === 2024 ? 2 : 1)
    }
    else {
      return Math.ceil(diffDays / 7) + 1;
    }
  }

  useEffect(() => {
    if (whenSelectingWeek === undefined || tsWeekStartChange === undefined) return;
    let today = new Date();
    today.setUTCHours(0, 0, 0, 0)
    let lastMonday = new Date(today);
    lastMonday.setUTCDate(lastMonday.getUTCDate() - (lastMonday.getUTCDay() + 6))
    let monday = new Date(lastMonday);
    let weeksOptions = [];
    while (monday > new Date(2021, 3, 5, 0, 0, 0, 0)) {
      let weekStart = new Date(monday);
      if (monday > tsWeekStartChange) {
        weekStart.setUTCDate(weekStart.getUTCDate() - 1)
      }
      weeksOptions.push({ name: `${weekStart.getUTCFullYear()} - Week ${getWeekOfYear(weekStart, tsWeekStartChange)} (${weekStart.getUTCDate().toString().padStart(2, '0')} ${MONTHS[weekStart.getUTCMonth()].substring(0, 3)})`, value: weekStart, selected: selectedWeek !== undefined ? `${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}` === `${weekStart.getUTCDate()}-${weekStart.getUTCMonth() + 1}-${weekStart.getUTCFullYear()}` : false, weekNumber: `${getWeekOfYear(weekStart, tsWeekStartChange)}`})
      monday.setUTCDate(monday.getUTCDate() - 7);
    }
    setWeeks(weeksOptions);
  }, [whenSelectingWeek, selectedWeek, tsWeekStartChange])

  useEffect(() => {
    if (whenSelectingMonth === undefined) return;
    let today = new Date();
    let monthsOptions = [];
    let i = 0;
    let month = today.getMonth() - i;
    let firstDayOfMonth = new Date(today.getFullYear(), month, 1);
    while (firstDayOfMonth > new Date(2023, 2, 1)) {
      monthsOptions.push({ name: `${MONTHS[firstDayOfMonth.getMonth()]} ${firstDayOfMonth.getFullYear()}`, value: firstDayOfMonth, selected: selectedMonth !== undefined ? `${selectedMonth.getDate()}-${selectedMonth.getMonth() + 1}-${selectedMonth.getFullYear()}` === `${firstDayOfMonth.getDate()}-${firstDayOfMonth.getMonth() + 1}-${firstDayOfMonth.getFullYear()}` : false })
      i += 1;
      month = today.getMonth() - i;
      firstDayOfMonth = new Date(today.getFullYear(), month, 1);
    }
    setMonths(monthsOptions);
  }, [whenSelectingMonth, selectedMonth])

  useEffect(() => {
    if (customPeriodsOptions === undefined) setCustomPeriodsOptions([
      { name: 'Current week', value: 0, selected: false },
      { name: 'Last week', value: 1, selected: true },
      { name: 'Current month', value: 2, selected: false },
      { name: 'Last month', value: 3, selected: false },
      { name: 'Last 6 month', value: 4, selected: false },
      { name: 'Last 12 month', value: 5, selected: false },
      { name: 'Life time', value: 6, selected: false }
    ]);
  }, [customPeriodsOptions])

  const whenSelectingCustomPeriod = useCallback((name, selection) => {
    const customMonthPeriodSelected = (startDate, endDate, months) => {
      startDate.setMonth(startDate.getMonth() - months)
      startDate.setDate(1)
      endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + months)
      endDate.setDate(endDate.getDate() - 1)
      return [startDate, endDate]
    }

    let startDate = new Date();
    let endDate = new Date();
    let range = [];
    switch (selection[0].value) {
      case 1:
        startDate = getLastNDay(new Date(), 6);
        endDate = getLastNDay(new Date(), 0);
        break;
      case 2:
        startDate.setDate(1);
        break;
      case 3:
        range = customMonthPeriodSelected(startDate, endDate, 1);
        break;
      case 4:
        range = customMonthPeriodSelected(startDate, endDate, 6);
        break;
      case 5:
        range = customMonthPeriodSelected(startDate, endDate, 12);
        break;
      case 6:
        range = customMonthPeriodSelected(startDate, endDate, 10000);
        break;
      default:
        startDate = getLastNDay(startDate, startDate.getDay() === 0 ? 6 : -1);
        break;
    }
    if (range.length > 1) {
      startDate = range[0];
      endDate = range[1];
    }
    whenSelectingDateRange('', { min: startDate, max: endDate });
  }, [whenSelectingDateRange])

  return (
    <div className={style.wrapper}>
      <div className={style.firstRow}>
        {
          breadcrumb !== undefined ? (
            <div className={style.pageBreadcrumb}>
              <Breadcrum
                path={breadcrumb}
                pageBreadcrumb={true} />
              <h2 className={style.pageTitle}>{pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1)}</h2>
            </div>
          ) : (
            null
          )
        }
        <div className={style.pageControls}>
          {
            whenSelectingVenue && venues ? (
              <VenueSelector venues={venues} multiple={false} callback={whenSelectingVenue} defaultVenue={selectedVenue} whenPrettyNames={whenPrettyNames} autoSelectFirstVenue={autoSelectFirstVenue} />
            ) : (
              null
            )
          }
          {
            whenSelectingDate ? (
              <VenueBaseDateSelect venue={selectedVenue} date={selectedDate} callback={whenSelectingDate} />
            ) : (
              null
            )
          }
          {
            customPeriods && customPeriodsOptions ? (
              <Select
                name='Custom periods'
                optionsList={customPeriodsOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingCustomPeriod} />
            ) : (
              null
            )
          }
          {
            whenSelectingDateRange && !customPeriods ? (
              <DateSelect
                name='Select date'
                defStartDate={selectedDateRange.min}
                defEndDate={selectedDateRange.max}
                callback={whenSelectingDateRange}
                range={true}
                displayToLeft={true} />
            ) : (
              null
            )
          }
          {
            whenSelectingWeek ? (
              <Select
                name='Week'
                optionsList={weeks}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingWeek} />
            ) : (
              null
            )
          }
          {
            whenSelectingMonth ? (
              <Select
                name='Month'
                optionsList={months}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingMonth} />
            ) : (
              null
            )
          }
          {
            whenSelectingCompany && companiesOptions && companiesOptions.length > 0 ? (
              <Select
                name='Company'
                optionsList={companiesOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingCompany}
                extraActions={false} />
            ) : (
              null
            )
          }
        </div>
      </div>
      <div className={style.secondRow}>
        <div className={style.pageControls}>
          {
            whenSelectingWeekDay && weekdayOptions ? (
              <Select
                name='Weekday'
                optionsList={weekdayOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingWeekDay} />
            ) : (
              null
            )
          }
          {
            whenSelectingShift && shiftOptions ? (
              <Select
                name='Shift'
                optionsList={shiftOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingShift} />
            ) : (
              null
            )
          }
          {
            whenSelectingRecipe && recipeOptions ? (
              <Select
                name='Recipe'
                optionsList={recipeOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingRecipe} />
            ) : (
              null
            )
          }
          {
            whenSelectingStandard && standarOptions ? (
              <Select
                name='Standard'
                optionsList={standarOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingStandard} />
            ) : (
              null
            )
          }
          {
            whenSelectingShowOnReport && showOnReportOptions ? (
              <Select
                name='Show on Report'
                optionsList={showOnReportOptions}
                multiple={false}
                checkboxes={false}
                callback={whenSelectingShowOnReport} />
            ) : (
              null
            )
          }
        </div>
      </div>
    </div>

  )
}

export default Header;
