import React, { useState, useEffect } from 'react';
import Score from '../score/Score';
import style from './Card.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faPlus, faQuestion, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { UI_TEXT } from '../../Constants';
import { DAYS, MONTHS } from '../../pizzaTv/Utils';

const Card = (props) => {
  const {
    cardIndex,
    imgLink,
    gtExtra,
    legend,
    specialty,
    crustType,
    score,
    callback,
    evaluationResult,
    multisize,
    censored,
    allowFeedback,
    reviewer,
    companyInfo,
    standardAttr,
    selected,
    markPizzaCard,
    filtered,
    user } = props;
  const [standardIcon, setStandardIcon] = useState(undefined);

  // We display size information only if the corresponding pipeline config is flagged as multisize.
  let showSize = multisize;

  useEffect(() => {
    if (standardAttr === undefined) {
      setStandardIcon(undefined);
      return;
    };
    setStandardIcon(standardAttr === 'to_standard' ? faCheck : standardAttr === 'not_to_standard' ? faPlus : faQuestion)
  }, [standardAttr])

  const cardStyles = {
    display: `${filtered ? 'block' : 'none'}`
  }

  return (
    <div className={style.wrapper} style={{...cardStyles, position: 'relative' }}>
      <div onClick={() => { callback(cardIndex) }}>
        <div className={style.imageWrapper}>
          <img className={style.image} src={imgLink} alt='' />
          {
            score && !user.daily_feed_only ? (
              <div className={style.overlayScore}>
                <Score
                  score={score}>
                </Score>
              </div>
            ) : (
              <React.Fragment />
            )
          }
        </div>
        <div className={style.body}>
          <div className={style.information}>
            <div>
              <span className={style.specialty}>{specialty}</span>
              {
                !user.daily_feed_only ? (
                  <div>
                    {
                      companyInfo.pj_store ? (
                        <span className={style.crust}>{`${UI_TEXT.crust[crustType] ? `Crust type: ${UI_TEXT.crust[crustType]}` : ''}`}</span>
                      ) : (
                        null
                      )
                    }
                    {
                      showSize && evaluationResult.info && evaluationResult.info.size_class ? (
                        <span className={style.crust}>Size: {evaluationResult.info.size_class}</span>
                      ) : (
                        null
                      )
                    }
                  </div>
                ) : (
                  null
                )
              }
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {
                allowFeedback && standardIcon !== undefined ? (
                  <div style={{
                    border: '1px solid #9d9da3',
                    borderRadius: '12px',
                    /* boxShadow: '5px 5px 5px -5px #333',*/
                    padding: '12px',
                    minWidth: '47px',
                    boxSizing: 'border-box',
                    lineHeight: '16px',
                    textAlign: 'center'
                  }}>
                    <FontAwesomeIcon style={{
                      color: `${standardIcon === faCheck ? '#00b050' : standardIcon === faPlus ? 'red' : '#ffd43b'}`,
                      transform: `${standardIcon === faPlus ? 'rotate(45deg)' : ''}`
                    }} icon={standardIcon} />
                  </div>
                ) : (
                  null
                )
              }
              {
                score && !user.daily_feed_only ? (
                  <div className={style.score}>
                    <Score
                      score={score}>
                    </Score>
                  </div>
                ) : (
                  <React.Fragment />
                )
              }
            </div>
          </div>
          <div className={style.legendWrapper}>
            {
              gtExtra ? (
                <FontAwesomeIcon className={style.commentButton} icon={faCommentDots} />
              ) : (
                <React.Fragment></React.Fragment>
              )
            }
            <span className={style.legend}>{`${DAYS[props.ts.getDay()]} ${props.ts.getHours().toString().padStart(2, '0')}:${props.ts.getMinutes().toString().padStart(2, '0')}:${props.ts.getSeconds().toString().padStart(2, '0')}`}</span>
            <span className={style.legend}>{`${MONTHS[props.ts.getMonth()]} ${props.ts.getDate().toString().padStart(2, '0')}, ${props.ts.getFullYear()}`}</span>
            <div className={style.feedbackProperties}>
              {
                censored ? (
                  <span className={style.hidden}>{'<HIDDEN>'}</span>
                ) : (
                  <React.Fragment />
                )
              }
              {
                allowFeedback && reviewer ? (
                  <span className={style.qaInspector}><FontAwesomeIcon style={{ marginRight: '5px' }} icon={faPen} /> {reviewer}</span>
                ) : (
                  <React.Fragment />
                )
              }
            </div>
          </div>
        </div>
      </div>
      {
        allowFeedback ? (
          <input
            className='large'
            style={{
              position: 'absolute',
              margin: '5px',
              top: '0',
              left: '0'
            }}
            checked={selected}
            onChange={(e) => { markPizzaCard(cardIndex, e.target.checked) }}
            type='checkbox'
            id={'featured' + cardIndex}>
          </input>
        ) : (
          null
        )
      }
    </div >
  )
}

export default Card;