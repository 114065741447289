import { FetchService } from '../../../services/Api';
import DateSelect from '../dateSelect/DateSelect';
import React, { useState, useEffect, useCallback, useRef } from 'react';

const VenueBaseDateSelect = (props) => {
  const { venue, callback, main, defStartDate, defEndDate } = props;
  const [selectedVenue, setSelectedVenue] = useState(undefined);
  const [yearMonth, setYearMonth] = useState(undefined);
  const [excludedDates, setExcludedDates] = useState(undefined);
  const [datesWithInformation, setDatesWithInformation] = useState({});
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (venue) {
      setSelectedVenue(venue)
      setDatesWithInformation({});
    }
  }, [venue])

  const updateInspectedMonth = (year, month) => {
    setYearMonth([year, month]);
  }

  const updateExcludedDates = useCallback((daysWithData, update) => {
    let newDatesWithInformation = datesWithInformation;
    if (update) newDatesWithInformation[`${yearMonth[0]}-${yearMonth[1]}`] = daysWithData;
    let excludedDates = [];
    let startDate = new Date(yearMonth[0], yearMonth[1] - 1, 1);
    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 62);
    endDate.setDate(1);
    if (((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false')) {
      for (let d = startDate; d < endDate; d.setDate(d.getDate() + 1)) {
        let day = String(d.getDate()).padStart(2, '0');
        let month = String(d.getMonth() + 1).padStart(2, '0');
        let year = d.getFullYear();
        let cd =  `${day}-${month}-${year}`;
        if (daysWithData.includes(cd)) continue;
        excludedDates.push(new Date(d))
      }
    }
    setExcludedDates(excludedDates);
    setDatesWithInformation(newDatesWithInformation);
  }, [datesWithInformation, yearMonth])

  useEffect(() => {
    if (!selectedVenue || !yearMonth) return;
    setExcludedDates(undefined);

    if (datesWithInformation[`${yearMonth[0]}-${yearMonth[1]}`] !== undefined) {
      updateExcludedDates(datesWithInformation[`${yearMonth[0]}-${yearMonth[1]}`]);
      return;
    }

    let requestDetails = {
      endpoint: 'charts/dates/',
      method: 'GET',
      data: {
        venue: selectedVenue,
        year: yearMonth[0],
        month: yearMonth[1].toString().padStart(2, '0')
      }
    }

    FetchService(requestDetails).then(
      (response) => {
        if (response && response.data) {
          if (!mountedRef.current) {
            return;
          }
          else {
            updateExcludedDates(response.data.data, true);
          }
        }
      }
    ).catch(err => console.log(err));
  }, [selectedVenue, yearMonth, datesWithInformation, updateExcludedDates]);

  useEffect(() => {
    if (!defStartDate) return;
    if (!yearMonth)
      setYearMonth([defStartDate.getFullYear(), defStartDate.getMonth() + 1]);
  }, [defStartDate, yearMonth])

  return <DateSelect
    name='Select date'
    defStartDate={defStartDate}
    defEndDate={defEndDate}
    onYearMonthChange={updateInspectedMonth}
    excludedDates={excludedDates}
    callback={callback}
    range={true}
    displayToLeft={true} 
    main={main}
    loadings />
}

export default VenueBaseDateSelect;