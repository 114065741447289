import React, { useState, useEffect } from 'react';
/* import Score from '../score/Score';
import ScoreHistogram from '../scoreHistogram/ScoreHistogram'; */
import style from './Summary.module.css';
/* import { faAngleRight } from '@fortawesome/free-solid-svg-icons'; */

const Summary = (props) => {
  const { scores, cardsCount, allowFeedback, reviewedCount, pizzaCount, summaryByReportSpecialties, censoredCount, reportSpecialtiesDefinition } = props;
  const [worstScore, setWorstScore] = useState('0');
  const [bestScore, setBestScore] = useState('0');
  const [averageScore, setAverageScore] = useState('0');
  const [scoredProportion, setScoredProportion] = useState(`0/${cardsCount}`);
  const [reviewedProportion, setReviewedProportion] = useState(`0/${cardsCount}`);
  const [censoredProportion, setCensoredProportion] = useState(`0/${cardsCount}`);

  useEffect(() => {
    if (scores.length > 0) {
      setWorstScore(Math.min(...scores).commaRepresentation(2));
      setBestScore(Math.max(...scores).commaRepresentation(2));
      setAverageScore((scores.reduce(function (a, b) { return parseFloat(a) + parseFloat(b) }) / scores.length).commaRepresentation(2));
      setScoredProportion(`${scores.length}/${pizzaCount}`);
      setReviewedProportion(`${reviewedCount}/${cardsCount}`);
      setCensoredProportion(`${censoredCount}/${reviewedCount}`);
    }
  }, [scores, cardsCount, reviewedCount, pizzaCount, censoredCount])

  return (
    <div className={style.wrapper}>
      <span className={style.title}>Summary</span>
      <div className={style.featuresRow}>
        <div className={style.feature}>
          <div className={style.featureDetail} style={{ alignItems: 'center' }}>
            <span className={style.featureValue} style={{ color: '#2864c8' }}>{scoredProportion}</span>
            <span className={style.featureLabel}>Scored pizzas</span>
          </div>
        </div>
        {
          allowFeedback ? (
            <div className={style.feature}>
              <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                <span className={style.featureValue} style={{ color: '#2864c8' }}>{reviewedProportion}</span>
                <span className={style.featureLabel}>Reviewed pizzas</span>
              </div>
            </div>
          ) : (
            null
          )
        }
        {
          allowFeedback ? (
            <div className={style.feature}>
              <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                <span className={style.featureValue} style={{ color: '#2864c8' }}>{censoredProportion}</span>
                <span className={style.featureLabel}>Hidden pizzas</span>
              </div>
            </div>
          ) : (
            null
          )
        }
        <div className={style.feature}>
          <div className={style.featureDetail} style={{ alignItems: 'center' }}>
            <span className={style.featureValue} style={{ color: '#F44336' }}>{parseFloat(worstScore).commaRepresentation(2)}</span>
            <span className={style.featureLabel}>Worst score</span>
          </div>
        </div>
        <div className={style.feature}>
          <div className={style.featureDetail} style={{ alignItems: 'center' }}>
            <span className={style.featureValue} style={{ color: '#35CF6C' }}>{parseFloat(bestScore).commaRepresentation(2)}</span>
            <span className={style.featureLabel}>Best score</span>
          </div>
        </div>
        <div className={style.feature}>
          <div className={style.featureDetail} style={{ alignItems: 'center' }}>
            <span className={style.featureValue} style={{ color: '#EEB406' }}>{parseFloat(averageScore).commaRepresentation(2)}</span>
            <span className={style.featureLabel}>Average score</span>
          </div>
        </div>
      </div>
      {
        allowFeedback && summaryByReportSpecialties !== undefined ? (
          <div className={style.featuresRow}>
            {
              Object.keys(summaryByReportSpecialties).map((specialty, index) => {
                return (
                  <div key={index} className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#2864c8' }}>{`${summaryByReportSpecialties[specialty].scored}/${summaryByReportSpecialties[specialty].count}`}</span>
                      <span className={style.featureLabel}>{`Scored ${reportSpecialtiesDefinition[specialty] ? (reportSpecialtiesDefinition[specialty].ui_text ?? specialty) : specialty }`}</span>
                    </div>
                  </div>
                )
              })
            }
          </div>
        ) : (
          null
        )
      }
      {/*       <div className={style.scoreCards}>
        <Score
          score={scoredProportion}
          label='Scored pizzas'
          color='#2864C8'>
        </Score>
        <Score
          score={worstScore}
          label='Worst score'
          color='#FF5E48'
          icon={faAngleRight}>
        </Score>
        <Score
          score={bestScore}
          label='Best score'
          color='#35CF6C'
          icon={faAngleRight}>
        </Score>
        <Score
          score={averageScore}
          label='Average score'
          color='#F8C71B'>
        </Score>
      </div> */}
      {/*       <ScoreHistogram
        scores={scores}>
      </ScoreHistogram> */}
    </div>
  )
}

export default Summary