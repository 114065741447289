import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FetchService } from '../../../services/Api';
import Header from '../../header/Header';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import style from './PizzaValidation.module.css';
import PizzaValidationPart1 from './pizzaValidationPart1/PizzaValidationPart1';
import PizzaValidationPart3 from './pizzaValidationPart3/PizzaValidationPart3';
import PizzaValidationScorelessReport from './pizzaValidationScorelessReport/PizzaValidationScorelessReport';
import OrdinalDate from '../../common/ordinalDate/OrdinalDate';
import { useNavigate } from 'react-router-dom';

const PizzaValidation = (props) => {
  const navigate = useNavigate();
  const { language, printReports } = props;
  const { venue, week, company } = useParams();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [recipeOptions, setRecipeOptions] = useState(undefined);
  const [weekdayOptions, setWeekdayOptions] = useState(undefined);
  const [shiftOptions, setShiftOptions] = useState(undefined);
  const [standarOptions, setStandarOptionss] = useState(undefined);
  const [showOnReportOptions, setShowOnReportOptions] = useState(undefined);
  const [reportsToBePrinted, setReportsToBePrinted] = useState(undefined);
  const [reportsToBePrintedIndex, setReportsToBePrintedIndex] = useState(0);
  const [canRenderStep, setCanRenderStep] = useState(true);
  const [specialtiesDefinition, setSpecialtiesDefinition] = useState(undefined);
  const [reportStructure, setReportStructure] = useState(undefined);

  useEffect(() => {
    if (printReports === undefined || reportsToBePrinted !== undefined || company === undefined || week === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/reports_to_be_printed/company_pk/${company}/week_stamp/${week}/`,
      method: 'GET'
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        setReportsToBePrinted(response.data.stores);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [printReports, reportsToBePrinted, company, week])

  useEffect(() => {
    if (weekdayOptions === undefined) setWeekdayOptions([
      { value: 'Sunday', name: 'Sunday' },
      { value: 'Monday', name: 'Monday' },
      { value: 'Tuesday', name: 'Tuesday' },
      { value: 'Wednesday', name: 'Wednesday' },
      { value: 'Thursday', name: 'Thursday' },
      { value: 'Friday', name: 'Friday' },
      { value: 'Saturday', name: 'Saturday' },
    ]);
  }, [weekdayOptions])

  useEffect(() => {
    if (shiftOptions === undefined) setShiftOptions([
      { value: 'AM', name: 'AM' },
      { value: 'PM', name: 'PM' }
    ])
  }, [shiftOptions])

  useEffect(() => {
    if (standarOptions === undefined) setStandarOptionss([
      { value: 'to_standard', name: 'To Standard' },
      { value: 'not_to_standard', name: 'Not to Standard' },
      { value: 'maybe_to_standard', name: 'Maybe to Standard' },
    ]);
  }, [standarOptions])

  useEffect(() => {
    if (showOnReportOptions === undefined) setShowOnReportOptions([
      { value: 1, name: 'True' },
      { value: 0, name: 'False' },
    ])
  }, [showOnReportOptions])

  useEffect(() => {
    if (venue === undefined || week === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/report/venue_pk/${venue}/week_stamp/${week}/`,
      method: 'GET'
    }
    setLoading(true);
    FetchService(requestConf)
      .then(response => setReport(response.data))
      .catch(err => console.log(err))
      .finally(res => setLoading(false));
  }, [venue, week])

  useEffect(() => {
    if (reportStructure !== undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/report_structure/`,
      method: 'GET'
    }
    setLoading(true);
    FetchService(requestConf)
      .then(response => setReportStructure(response.data))
      .catch(err => console.log(err));
  }, [reportStructure])

  useEffect(() => {
    if (reportsToBePrinted === undefined || reportsToBePrinted.length === 0) return;
    setCanRenderStep(false);
    let requestConf = {
      endpoint: `pizza_tv/report/venue_pk/${reportsToBePrinted[reportsToBePrintedIndex]}/week_stamp/${week}/`,
      method: 'GET'
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        setReport(response.data);
        setCanRenderStep(true);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [reportsToBePrinted, reportsToBePrintedIndex, week])

  const addFilter = useCallback((name, selection) => {
    let eval_functions = {
      Weekday: (card, selection) => {
        if (selection.length !== 1) return true;
        return card.weekday === selection[0].value;
      },
      Shift: (card, selection) => {
        if (selection.length !== 1) return true;
        return card.shift === selection[0].value;
      },
      Recipe: (card, selection) => {
        if (selection.length !== 1) return true;
        return card.specialty === selection[0].value;
      },
      Standard: (card, selection) => {
        if (selection.length !== 1) return true;
        return card.standard === selection[0].value;
      },
      'Show on Report': (card, selection) => {
        if (selection.length !== 1) return true;
        return card.show_on_report === selection[0].value;
      },
    }

    let temp = filters;
    for (let i = 0; i < filters.length; ++i) {
      if (filters[i].name === name) {
        if (selection.length === 0) {
          temp.splice(i, 1);
        }
        else {
          temp[i].selection = selection;
        }
        setFilters([...temp]);
        return;
      }
    }
    temp.push({ name: name, selection: selection, eval: eval_functions[name] });
    setFilters([...temp]);
  }, [filters])

  const changeReportState = useCallback((key) => {
    let requestConf = {
      endpoint: `pizza_tv/report/${key}_step/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        report_id: report._id,
      }
    }
    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        if (response.status === 200) {
          setFilters([]);
          setReport(response.data.report);
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [report])

  const fillReport = useCallback(() => {
    let requestConf = {
      endpoint: `pizza_tv/fill_report/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        report_id: report._id,
      }
    }
    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        if (response.status === 200) {
          setReport(response.data.report);
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [report])

  const whenSpecialtiesIdentified = useCallback((specialties) => {
    for (let specialty of specialties) {
      if (recipeOptions === undefined || !recipeOptions.map(opt => opt.value).includes(specialty)) {
        const options = [];
        for (let specialty_ of specialties) {
          options.push({
            value: specialty_, name: specialty_
          })
        }
        setRecipeOptions(options);
        break;
      }
    }
  }, [recipeOptions])

  const afterPrint = useCallback(() => {
    if (reportsToBePrinted === undefined) return
    else if (reportsToBePrintedIndex >= reportsToBePrinted.length - 1) {
      window.alert('Auto generation process finished');
      navigate(`/pizza-tv-explorer`);
    }
    else {
      setReportsToBePrintedIndex(reportsToBePrintedIndex + 1);
    }
  }, [reportsToBePrintedIndex, reportsToBePrinted, navigate])

  const changeState = useCallback((report) => {
    setShiftOptions(undefined);
    setRecipeOptions(undefined);
    setWeekdayOptions(undefined);
    setStandarOptionss(undefined);
    setShowOnReportOptions(undefined);
    setFilters([]);
    setReport(report);
  }, [])

  useEffect(() => {
    if (reportStructure === undefined || reportStructure.REPORT_SPECIALTIES_DEFINITION === undefined || specialtiesDefinition !== undefined) return;
    setSpecialtiesDefinition(reportStructure.REPORT_SPECIALTIES_DEFINITION);
  }, [reportStructure, specialtiesDefinition])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        {
          company !== undefined ? (
            reportsToBePrinted !== undefined ? (
              reportsToBePrinted.length === 0 ? (
                <span>No reports to be generated</span>
              ) : (
                <span>
                  {`Generating ${reportsToBePrintedIndex + 1}/${reportsToBePrinted.length} reports (${reportsToBePrinted[reportsToBePrintedIndex]})`}
                </span>
              )
            ) : (
              null
            )
          ) : (
            report !== undefined ? (
              <span>
                <OrdinalDate date={new Date(report.week_stamp)} /> - <OrdinalDate date={new Date(new Date(report.week_stamp).getTime() + 6 * 24 * 60 * 60 * 1000)} />, {new Date(report.week_stamp).getFullYear()}
              </span>
            ) : (
              null
            )
          )
        }
        <Header
          whenSelectingWeekDay={report && (report.state === 'data selection' || report.state === 'scoreless data selection') ? addFilter : undefined}
          weekdayOptions={weekdayOptions}
          whenSelectingShift={report && (report.state === 'data selection' || report.state === 'scoreless data selection') ? addFilter : undefined}
          shiftOptions={shiftOptions}
          whenSelectingRecipe={report && report.state === 'data selection' && !report.scoreless_report ? addFilter : undefined}
          recipeOptions={recipeOptions}
          whenSelectingStandard={report && report.state === 'data selection' && !report.scoreless_report ? addFilter : undefined}
          standarOptions={standarOptions}
          whenSelectingShowOnReport={report && (report.state === 'data selection' || report.state === 'scoreless data selection') ? addFilter : undefined}
          showOnReportOptions={showOnReportOptions} />
        <div className={style.pageContent}>
          {
            canRenderStep ? (
              report && report.state === 'data selection' ? (
                <PizzaValidationPart1
                  specialtiesDefinition={specialtiesDefinition}
                  changeState={changeState}
                  changeReportState={changeReportState}
                  fillReport={fillReport}
                  filters={filters}
                  report={report}
                  language={language}
                  whenSpecialtiesIdentified={whenSpecialtiesIdentified}
                  reportStructure={reportStructure} />
              ) : (
                report && report.state === 'scoreless data selection' ? (
                  <PizzaValidationPart1
                    changeState={changeState}
                    changeReportState={changeReportState}
                    fillReport={fillReport}
                    filters={filters}
                    report={report}
                    language={language}
                    whenSpecialtiesIdentified={whenSpecialtiesIdentified}
                    reportStructure={reportStructure} />
                ) : (
                  report && ['complete', 'generated', 'delivered'].includes(report.state) ? (
                    report.scoreless_report ? (
                      <PizzaValidationScorelessReport
                        weekStamp={week}
                        autoPrint={company !== undefined}
                        afterPrint={afterPrint}
                        changeState={changeState}
                        changeReportState={changeReportState}
                        editable={props.editable}
                        report={report} />
                    ) : (
                      <PizzaValidationPart3
                        weekStamp={week}
                        changeState={changeState}
                        autoPrint={company !== undefined}
                        afterPrint={afterPrint}
                        changeReportState={changeReportState}
                        editable={props.editable}
                        report={report}
                        reportStructure={reportStructure} />
                    )
                  ) : (
                    null
                  )
                )
              )
            ) : (
              null
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default PizzaValidation;