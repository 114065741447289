import React, { useState, useEffect, useCallback } from 'react';
import Breadcrum from '../../common/breadcrumb/Breadcrumb';
import Toggle from '../toggle/Toggle';
import Score from '../score/Score';
import PizzaFeedback from '../pizzaFeedback/PizzaFeedback';
import PizzaDetail from '../pizzaDetail/PizzaDetail';
import style from './CardDetail.module.css';
import close from '../../../vectors/close.svg';
import { CARDS_FILTERS, UI_TEXT } from '../../Constants';
import { FetchService } from '../../../services/Api';
import { faPen, faPlus, faQuestion, faCheck, faInfoCircle, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import { getSpecialtyDisplayName } from '../Aux';
import ModalWrapper from '../../common/modalWrapper/ModalWrapper';


const PIZZA_ATTRIBUTES = [
  { value: 'blurry_general', name: 'Blurry - General' },
  { value: 'blurry_tornado', name: 'Blurry - Tornado' },
  { value: 'camera_position_lateral_view_or_lopsided', name: 'Camera position - Lateral view or lopsided' },
  { value: 'camera_position_off_centered', name: 'Camera position - Off centered' },
  { value: 'foggy', name: 'Foggy' },
  { value: 'light_color', name: 'Light - Color' },
  { value: 'light_too_bright', name: 'Light - Too bright' },
  { value: 'light_too_dark', name: 'Light - Too dark' },
  { value: 'not_a_pizza_object', name: 'Not a pizza - Object' },
  { value: 'not_a_pizza_other_food_items', name: 'Not a pizza - Other food items' },
  { value: 'occluded_cable', name: 'Occluded – Cable' },
  { value: 'occluded_person', name: 'Occluded – Person' },
  { value: 'occluded_person', name: 'Occluded – Person' },
  { value: 'occluded_pizza_box', name: 'Occluded – Pizza Box' },
  { value: 'pizza_size_ppm', name: 'Pizza size (PPM)' },
]


const CardDetail = (props) => {
  const {
    closeCardDetail,
    openFeebackDialog,
    index,
    companyInfo,
    crustType,
    allowFeedback,
    updatePizzaAttr,
    updateScoreDetails,
    updatePizzaData,
    score,
    reviewer,
    evaluationResult,
    evaluationRun,
    originalData,
    attributes,
    language,
    standardAttr,
    pizzaInfoId,
    tId,
    changeCard,
    censored,
    bubbles,
    overbaked,
    badCut,
    user } = props;
  const [editing, setEditing] = useState(false);
  const [editingHeader, setEditingHeader] = useState(false);
  const [dataToSave, setDataToSave] = useState({});
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [scoreCriteria, setScoreCriteria] = useState(props.gtScoreCriteria);
  const [loading, setLoading] = useState(false);
  const [selectedMode, setSelectedMode] = useState(0);
  const [deltaScore, setDeltaScore] = useState(undefined);
  const [standardIcon, setStandardIcon] = useState(undefined);
  const [allowStandardFeedback, setAllowStandardFeedback] = useState(false);
  const [pizzaInfo, setPizzaInfo] = useState(undefined);
  const [displayPizzaInfo, setDisplayPizzaInfo] = useState(false);
  const [pizzaVisibilityToggle, setPizzaVisibilityToggle] = useState(undefined);
  const [pizzaBubblesToggle, setPizzaBubblesToggle] = useState(undefined);
  const [pizzaOverbakedToggle, setPizzaOverbakedToggle] = useState(undefined);
  const [pizzaCutToggle, setPizzaCutToggle] = useState(undefined);

  useEffect(() => {
    setDeltaScore(undefined);
  }, [index])

  useEffect(() => {
    if ((dataToSave['Specialty'] && dataToSave['Specialty'].length > 0) || (originalData && originalData.specialty !== undefined)) {
      setAllowStandardFeedback(true);
      return;
    }
    setAllowStandardFeedback(false);
  }, [dataToSave, originalData])

  useEffect(() => {
    if (score === '' || originalData === undefined || originalData === null || originalData.result === undefined || !allowFeedback) return;
    setDeltaScore(Math.round(((originalData.result.score_final - parseFloat(score)) + Number.EPSILON) * 100) / 100)
  }, [score, allowFeedback, originalData])

  const changeMode = (newMode, callback) => {
    setSelectedMode(newMode);
    if (callback)
      callback(newMode);
  }

  const changePizzaAttr = useCallback((attr, val, callback) => {
    setLoading(true);
    let config = {
      endpoint: `dashboard/qa_feedback/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        t_id: props.tId,
        pipe_id: props.pipeId,
        vid_id: props.vidId
      }
    }
    config.data[attr] = typeof val === 'string' ? val : val ? 1 : 0;

    FetchService(config).then(
      (response) => {
        if (attr in response.data) {
          if (callback)
            callback(val);
          if (updatePizzaAttr) {
            updatePizzaAttr(index, response.data, attr);
          }
        }
        if (response.data.score_details) {
          if (updateScoreDetails) {
            updateScoreDetails(index, response.data);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally(() => setLoading(false));
  }, [index, props, updatePizzaAttr, updateScoreDetails])

  const modes = [
    { name: 'Global', callback: changeMode, active: true },
    { name: 'Details', callback: changeMode, active: false }
  ];

  useEffect(() => {
    setPizzaVisibilityToggle([{ name: 'Hide picture', callback: (val, callback) => { changePizzaAttr('censored', val, callback) }, active: censored }]);
  }, [censored, changePizzaAttr])
  
  useEffect(() => {
    setPizzaBubblesToggle([{ name: 'Bad pizza - Bubbles', callback: (val, callback) => { changePizzaAttr('bubbles', val, callback) }, active: bubbles }]);
  }, [bubbles, changePizzaAttr])

  useEffect(() => {
    setPizzaOverbakedToggle([{ name: 'Bad pizza - Overbaked', callback: (val, callback) => { changePizzaAttr('overbaked', val, callback) }, active: overbaked }]);
  }, [overbaked, changePizzaAttr])

  useEffect(() => {
    setPizzaCutToggle([{ name: 'Bad pizza - Cut', callback: (val, callback) => { changePizzaAttr('badCut', val, callback) }, active: badCut }]);
  }, [badCut, changePizzaAttr])

  useEffect(() => {
    if (companyInfo === undefined || crustType === undefined) return;
    let newConf = [
      ...(allowFeedback ? [{
        name: `Pizza attributes`,
        editable: {
          name: 'Pizza attributes',
          multiple: true,
          checkboxes: true,
          quickSelection: false,
          optionsList: PIZZA_ATTRIBUTES.map(attr => { return { value: attr.value, name: attr.name, selected: ![undefined, null].includes(attributes) ? attributes.includes(attr.value) : false } })
        }
      }] : []),
      {
        name: `${props.specialty}${originalData && originalData.specialty ? ` (${getSpecialtyDisplayName(originalData.specialty, language)})` : ''}`,
        editable: {
          ...CARDS_FILTERS['All specialties'],
          quickSelection: true,
          optionsList: CARDS_FILTERS['All specialties'].optionsList.map(specialty => { return { value: specialty.db_value, name: specialty.name, selected: specialty.name === props.specialty } }),
          multiple: false,
          name: 'Specialty'
        }
      },
      ...(crustType ? [{
        name: `${companyInfo.pj_store || allowFeedback ? (UI_TEXT.crust[crustType] ?? crustType) : ''}${originalData && originalData.crust ? ` (${UI_TEXT.crust[originalData.crust] ?? originalData.crust})` : ''}`,
        quickSelection: true,
        editable: {
          name: 'Crust type',
          optionsList: companyInfo.feedbackCrusts.map(crust => { return { value: crust, name: UI_TEXT.crust[crust] ?? crust, selected: crustType === crust ? true : false } }),
          multiple: false,
        }
      }] : [])
    ]
    if (user.daily_feed_only) {
      newConf.pop()
    }
    setBreadcrumb(newConf);
  }, [props.specialty, companyInfo, crustType, attributes, originalData, allowFeedback, language]);

  const handleEdition = (onlyHeader = false) => {
    if ((editing === true && !onlyHeader) || (editingHeader === true && onlyHeader)) {
      setLoading(true);
      let data = {
        endpoint: 'dashboard/qa_feedback/',
        method: 'POST',
        dataType: 'JSON',
        data: {
          t_id: props.tId,
          pipe_id: props.pipeId,
          vid_id: props.vidId,
          ...(onlyHeader && dataToSave['Pizza attributes'] && { attributes: dataToSave['Pizza attributes'].map((attr, index) => { return attr.value }) }),
          ...(onlyHeader && dataToSave['Specialty'] && dataToSave['Specialty'].length > 0 && { specialty: dataToSave['Specialty'][0].value }),
          ...(onlyHeader && dataToSave['Crust type'] && dataToSave['Crust type'].length > 0 && { crust: dataToSave['Crust type'][0].value }),
          ...(!onlyHeader && { score_criteria: scoreCriteria })
        }
      }
      FetchService(data).then(
        (response) => {
          if (response.data.score_details) {
            if (updateScoreDetails) {
              updateScoreDetails(index, response.data);
            }
          }
          if (response.data.crust || response.data.specialty || response.data.attributes) {
            if (updatePizzaData) {
              updatePizzaData(index, response.data)
            }
          }
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err)).finally(() => setLoading(false));
      if (onlyHeader) setEditingHeader(false);
      else setEditing(false);
    }
    else {
      if (onlyHeader) setEditingHeader(true);
      else setEditing(true);
    }
  }

  const updateDataToSave = (key, selection) => {
    let temp = dataToSave;
    temp[key] = selection;
    setDataToSave({ ...temp });
  }

  const updateScoreCriteria = (key, value) => {
    let temp = scoreCriteria;
    if (temp === '' || temp === undefined) temp = {};
    temp[key] = value;
    setScoreCriteria(temp);
  }

  useEffect(() => {
    if (standardAttr === undefined) return;
    setStandardIcon(standardAttr === 'to_standard' ? faCheck : standardAttr === 'not_to_standard' ? faPlus : faQuestion)
  }, [standardAttr])

  const getPizzaInfo = useCallback((id) => {
    let data = {
      endpoint: `dashboard/per_pizza_info/tracking_id/${id}/`,
      method: 'GET'
    }
    setLoading(true);
    FetchService(data)
      .then(response => {
        setPizzaInfo(response.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [])

  useEffect(() => {
    if (pizzaInfo !== undefined) setDisplayPizzaInfo(true);
  }, [pizzaInfo])

  useEffect(() => {
    function handleEscPressed(event) {
      event.preventDefault();
      if (event.keyCode === 37 || event.keyCode === 38) {
        changeCard(-1);
      }
      if (event.keyCode === 39 || event.keyCode === 40) {
        changeCard(1);
      }
    }
    document.addEventListener('keydown', handleEscPressed);
    return () => {
      document.removeEventListener('keydown', handleEscPressed);
    };
  });

  return (
    <div>
      {
        displayPizzaInfo ? (
          <ModalWrapper
            allScreen={true}
            fitContent={true}
            display={displayPizzaInfo}
            setDisplay={setDisplayPizzaInfo} >
            <div style={{ backgroundColor: 'grey', width: '800px', textAlign: 'left', padding: '10px' }}>
              <h3>Pizza ID: {tId}</h3>
              <div style={{ backgroundColor: 'darkgrey', height: '300px', overflowY: 'auto', marginBottom: '10px' }}>
                <p>edge_tracking_summary</p>
                <pre>{JSON.stringify(pizzaInfo.edge_tracking_summary, null, 2)}</pre>
              </div>
              <div style={{ backgroundColor: 'darkgrey', height: '300px', overflowY: 'auto', marginBottom: '10px' }}>
                <p>evaluation</p>
                <pre>{JSON.stringify(pizzaInfo.evaluation, null, 2)}</pre>
              </div>
              <div style={{ backgroundColor: 'darkgrey', height: '300px', overflowY: 'auto' }}>
                <p>evaluation_runs</p>
                <pre>{JSON.stringify(pizzaInfo.evaluation_runs, null, 2)}</pre>
              </div>
            </div>
          </ModalWrapper>
        ) : (
          null
        )
      }
      <button style={{ position: 'absolute', left: '-60px', top: '50%', marginTop: '-20px', background: 'white', border: 'none', padding: '10px' }} onClick={() => changeCard(-1)}><FontAwesomeIcon style={{ width: '40px', height: '40px' }} icon={faArrowLeft} /></button>
      <button style={{ position: 'absolute', right: '-60px', top: '50%', marginTop: '-20px', background: 'white', border: 'none', padding: '10px' }} onClick={() => changeCard(1)}><FontAwesomeIcon style={{ width: '40px', height: '40px' }} icon={faArrowRight} /></button>
      <div className={style.wrapper}>
        <LoadingScreen loading={loading} adaptable={true}>
          <div className={style.header}>
            <div className={style.title}>
              <span className={style.specialty}>{props.specialty}</span>
              <span className={style.legend}>{props.legend}</span>
              {
                allowFeedback && reviewer ? (
                  <span className={style.qaInspector}><FontAwesomeIcon style={{ marginRight: '10px' }} icon={faPen} /> {reviewer}</span>
                ) : (
                  <React.Fragment />
                )
              }
            </div>
            <div style={{ display: 'flex' }}>
              {
                allowFeedback ? (
                  <button style={{ border: 'none', background: 'none', fontSize: '50px' }} onClick={() => getPizzaInfo(pizzaInfoId)}><FontAwesomeIcon style={{ color: '#2864C8' }} icon={faInfoCircle} /></button>
                ) : (
                  null
                )
              }
              <img className={style.closeButton} onClick={() => closeCardDetail()} src={close} alt='Close' />
            </div>
          </div>
          <div className={style.secondHeader}>
            <div className={style.breadcrumbEdit}>
              <Breadcrum
                path={breadcrumb}
                editing={editingHeader}
                callback={updateDataToSave}>
              </Breadcrum>
              {
                allowFeedback ? (
                  <button className={style.editHeader} onClick={e => { setEditingHeader(!editingHeader); handleEdition(true); }}><FontAwesomeIcon className={style.editHeaderIcon} icon={faPen} />
                    {editingHeader ? ('Give Feedback') : ('Data Feedback')}
                  </button>
                ) : (
                  <React.Fragment />
                )
              }
            </div>
            <div className={style.toggleScore}>
              {
                score !== '' && companyInfo.pj_store && !props.kwali_score && modes.length > 0 && !user.daily_feed_only ? (
                  <Toggle
                    modes={modes}>
                  </Toggle>
                ) : (
                  <div></div>
                )
              }
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {
                  allowFeedback && standardIcon !== undefined ? (
                    <div style={{
                      border: '1px solid #9d9da3',
                      borderRadius: '12px',
                      /* boxShadow: '5px 5px 5px -5px #333',*/
                      padding: '12px',
                      minWidth: '47px',
                      boxSizing: 'border-box',
                      lineHeight: '16px',
                      textAlign: 'center'
                    }}>
                      <FontAwesomeIcon style={{
                        color: `${standardIcon === faCheck ? '#00b050' : standardIcon === faPlus ? 'red' : '#ffd43b'}`,
                        transform: `${standardIcon === faPlus ? 'rotate(45deg)' : ''}`
                      }} icon={standardIcon} />
                    </div>
                  ) : (
                    null
                  )
                }
                {
                  score !== '' && !user.daily_feed_only ? (
                    <div className={style.scoreWrapper}>
                      {
                        deltaScore !== undefined ? (
                          <span style={{ color: deltaScore < 0 ? '#35CF6C' : deltaScore > 0 ? '#FF5E48' : '#2864C8' }} className={style.deltaScore}>
                            {`(${deltaScore > 0 ? '-' : '+'} ${(Math.abs(deltaScore)).commaRepresentation(2)})`}
                          </span>
                        ) : (
                          null
                        )
                      }
                      <div className={style.score}>
                        <Score
                          score={score}>
                        </Score>
                      </div>
                    </div>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          </div>
          <div className={style.body}>
            {
              props.kwali_score || (modes.length !== 0 && selectedMode === 0) || allowStandardFeedback ? (
                <PizzaFeedback
                  allowStandardFeedback={allowStandardFeedback}
                  companyInfo={companyInfo}
                  deltaScore={deltaScore}
                  allowFeedback={allowFeedback}
                  editing={editing}
                  kwali_score={props.kwali_score}
                  imgLink={props.imgLink}
                  evaluationResult={evaluationResult}
                  evaluationRun={evaluationRun}
                  score={score}
                  openFeebackDialog={openFeebackDialog}
                  updateScoreCriteria={updateScoreCriteria}
                  editionCallback={handleEdition}
                  omitSoft={props.omitSoft}
                  rawScore={props.rawScore}
                  specialty={props.category}
                  originalData={originalData}
                  pizzaVisibilityToggle={pizzaVisibilityToggle}
                  pizzaBubblesToggle={pizzaBubblesToggle}
                  pizzaOverbakedToggle={pizzaOverbakedToggle}
                  pizzaCutToggle={pizzaCutToggle}
                  changePizzaAttr={changePizzaAttr}
                  standard={props.standard}
                  user={user} />
              ) : (
                <PizzaDetail
                  allowFeedback={allowFeedback && companyInfo.pj_store}
                  editing={editing}
                  imgLink={props.imgLink}
                  score={score}
                  evaluationResult={evaluationResult}
                  openFeebackDialog={openFeebackDialog}
                  editionCallback={handleEdition}
                  omitSoft={props.omitSoft}
                  rawScore={props.rawScore}
                  specialty={props.category} />
              )
            }
          </div>
        </LoadingScreen>
      </div>
    </div>
  )
}

export default CardDetail;